<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                    <!--Новый механизм построения шаблона-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12">
                            <v-card flat>
                                <v-card-text>
                                    <!--
                                    <div class="form-box-title title-with-right-part">
                                        <span>{{$t("Создание_шаблона")}}</span>
                                        <div class="twrp-content">
                                            <v-text-field
                                                v-model="manualTemplateItem"
                                                :placeholder='$t("Ввести_вручную")'
                                                hide-details
                                                dense
                                                clearable
                                                @click:append="onAddManualTemplateItem"
                                                class="twrp-search"
                                                append-icon="mdi-chevron-right"
                                            >
                                            </v-text-field>
                                        </div>
                                    </div>-->

                                    <v-field 
                                        label="Доступно"
                                        :wide="true"
                                    >

                                        <template v-slot:edit>
                                            <draggable
                                                v-model="templateItems"
                                                v-bind="draggableOptions"
                                                @start="onDragStart"
                                                @end="onDragEnd"
                                                :class="['more-per-lab-wrap', 'onlyReadData', 'largeDragArea']"
                                            >
                                                <template v-for="(item, index) in templateItems">
                                                    <v-chip
                                                        :key="index"
                                                        class="person-label immutable-text"
                                                        small
                                                        label
                                                        v-if="item.includes('[') && item.includes(']')"
                                                    >
                                                        <div 
                                                            class="font-weight-bold"
                                                            :style="{ color: item.includes('[СЧЕТЧИК]') ? '#E43958' : '' }"
                                                        >
                                                            {{ removeSquareBracket(item) }}
                                                        </div>
                                                    </v-chip>
                                                </template>
                                            </draggable>
                                        </template>

                                    </v-field>

                                    <v-field 
                                        label="Выбрано"
                                        :wide="true"
                                    >

                                        <template v-slot:edit>
                                            <draggable
                                                v-model="selectedTemplateItems"
                                                v-bind="draggableOptions"
                                                @start="onDragStart"
                                                @end="onDragEnd"
                                                :class="[dragStart ? 'isDragClass' : '', 'more-per-lab-wrap onlyReadData largeDragArea']"
                                            >
                                                <template v-for="(item, index) in selectedTemplateItems">
                                                    <div 
                                                        :key="index"
                                                        class="dragItemWithAdd"
                                                    >
                                                        <v-chip
                                                            :key="index"
                                                            class="person-label immutable-text"
                                                            small
                                                            label
                                                            v-if="item.includes('[') && item.includes(']')"
                                                        >
                                                            <div class="font-weight-bold">{{ removeSquareBracket(item) }}</div>
                                                        </v-chip>
                                                        <v-chip
                                                            :key="index"
                                                            class="person-label immutable-text"
                                                            small
                                                            label
                                                            v-else
                                                        >
                                                            <div>{{ item }}</div>
                                                            <div class="pl-actions-wrapper">
                                                                <span
                                                                    v-tooltip.left-center="$t('Удалить')"
                                                                    @click="onSelectedTemplateItemClick(index)"
                                                                >
                                                                    <i class="fas fa-times dark-gray-color"></i>
                                                                </span>
                                                            </div>
                                                        </v-chip>

                                                        <v-slide-x-transition 
                                                            mode="out-in"
                                                            leave-absolute
                                                        >
                                                            <div 
                                                                class="d-flex align-center"
                                                                @click="selectAfterDragItem(index)"
                                                                    v-if="
                                                                        (item.includes('[') && item.includes(']')) &&
                                                                        (index + 1) < selectedTemplateItems.length &&
                                                                        (selectedTemplateItems[index + 1].includes('[') &&
                                                                        selectedTemplateItems[index + 1].includes(']')) &&
                                                                        index !== activeAfterDragItem
                                                                    "
                                                            >
                                                                <v-icon 
                                                                    class="dragAddItemBtn"
                                                                    small
                                                                >
                                                                fas fa-plus-circle
                                                                </v-icon>
                                                            </div>
                                                        </v-slide-x-transition>
                                                        <v-slide-x-reverse-transition 
                                                            mode="out-in"
                                                            leave-absolute
                                                        >
                                                            <v-text-field
                                                                v-if="
                                                                    (item.includes('[') && item.includes(']')) &&
                                                                    (index + 1) < selectedTemplateItems.length &&
                                                                    (selectedTemplateItems[index + 1].includes('[') &&
                                                                    selectedTemplateItems[index + 1].includes(']')) &&
                                                                    index === activeAfterDragItem
                                                                "
                                                                v-model="manualTemplateItem[index]"
                                                                :placeholder='$t("Ввести_вручную")'
                                                                hide-details
                                                                dense
                                                                solo
                                                                class="dragAddItemInput"
                                                                color="#494F59"
                                                                autofocus
                                                            >
                                                                <template slot="append">
                                                                    <div class="pl-actions-wrapper">
                                                                        <i 
                                                                            class="fa fa-check"
                                                                            @click="onAddManualTemplateItemIndex(index)"
                                                                            v-if="manualTemplateItem[index]"
                                                                            v-tooltip.left-center="$t('Сохранить')"
                                                                        ></i>
                                                                        <i 
                                                                            class="fa fa-times"
                                                                            @click="onCloseManualTemplateItemIndex(index)"
                                                                            v-tooltip.left-center="$t('Удалить')"
                                                                        ></i>
                                                                    </div>
                                                                </template>
                                                            </v-text-field>
                                                        </v-slide-x-reverse-transition>
                                                    </div>
                                                </template>
                                            </draggable>
                                        </template>

                                    </v-field>

                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{$t("Пример")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="10">
                                            <v-textarea
                                                class="oneRowStartTextarea"
                                                rows="1"
                                                auto-grow
                                                row-height="16"
                                                no-resize
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                readonly
                                                :value="result"
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan" 
                        text
                        depressed
                        @click="ok"
                        v-if="selectedTemplateItems.length > 0 && selectedTemplateItems.includes('[СЧЕТЧИК]')"
                    >
                        {{$t("Выбрать")}}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import draggable from 'vuedraggable'

export default {
    name: "TemplateNumberDlg",
    components: {
        draggable
    },
    data () {
        return {
            title: i18n.t("Шаблон_номера"),            
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            manualTemplateItem: [],
            templateItems: [],
            selectedTemplateItems: [],
            //drag
            dragStart: false,
            draggableOptions: {
                animation: 300,
                group: "group",
                disabled: false,
            },
            activeAfterDragItem: null,
        }
    },
    computed: {
        result() {
            return this.selectedTemplateItems.join('');
        }
    },
    methods: {
        async open(template, templateItems, options = {}) {
            this.selectedTemplateItems = this.convertTemplateToItemsArray(template);
            this.templateItems = templateItems.filter( ( el ) => !this.selectedTemplateItems.includes( el ) );
            this.visible = true;
            this.search = null;
            this.options = Object.assign(this.options, options);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        onSelectTemplateItem(item) {
            this.selectedTemplateItems.push(item);
        },
        onAddManualTemplateItem() {
            if (this.manualTemplateItem.length > 0 && this.manualTemplateItem.trim().length > 0) {
                this.selectedTemplateItems.push(this.removeSquareBracket(this.manualTemplateItem));
                //Array.prototype.push.apply(this.selectedTemplateItems, [[this.manualTemplateItem]]);
                this.manualTemplateItem = "";
            }
        },
        onAddManualTemplateItemIndex(index) {
            if (this.manualTemplateItem[index].length > 0 && this.manualTemplateItem[index].trim().length > 0) {
                this.selectedTemplateItems.splice(index + 1, 0, this.removeSquareBracket(this.manualTemplateItem[index]));
                this.manualTemplateItem[index] = "";
                this.activeAfterDragItem = null;
            }
        },
        onCloseManualTemplateItemIndex(index) {
            this.manualTemplateItem[index] = "";
            this.activeAfterDragItem = null;
        },
        onSelectedTemplateItemClick(index) {
            this.selectedTemplateItems.splice(index, 1);
            this.activeAfterDragItem = null;
        },
        ok() {
            this.visible = false;
            this.resolve(this.result);
        },
        cancel() {
            this.visible = false;

            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });
        },
        convertTemplateToItemsArray(str) {
            var target = "[";
            var target2 = "]";
            var obj = [];
            var pos = -1;
            
            while ((pos = str.indexOf(target)) != -1) {
                if (pos == 0) {
                    let start = str.indexOf(target, 0);
                    let end = str.indexOf(target2, 0);
                    obj.push(str.slice(start, end + 1));
                    str = str.substr(end + 1);
                    pos = -1;
                }
                else {
                    let start = str.indexOf(target, 0);
                    let end = str.indexOf(target2, 0);
                    obj.push(str.slice(0, start));
                    obj.push(str.slice(start, end + 1));
                    str = str.substr(end + 1);
                }
            }

            return obj;
        },
        onDragStart() {
            this.dragStart = true;
        },
        onDragEnd() {
            this.dragStart = false;
        },
        removeSquareBracket(text) {
            return text.replaceAll('[', '').replaceAll(']', '');
        },
        selectAfterDragItem(index) {
            this.activeAfterDragItem = index;
        }
    }
}
</script>